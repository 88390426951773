/// //////////////////////////////////////////////////////////////////////////
/// /// temporary fix to bug about 'Setting a timer' /////////////////////////
/// /// See: https://github.com/pusher/pusher-js/issues/248 //////////////////
/// /// See: https://github.com/facebook/react-native/issues/12981 ///////////
/// /// See: https://github.com/firebase/firebase-js-sdk/issues/97 ///////////
/// //////////////////////////////////////////////////////////////////////////
import { InteractionManager, Platform } from 'react-native';

import App from './src/App';

const _setTimeout = global.setTimeout;
const _clearTimeout = global.clearTimeout;
const MAX_TIMER_DURATION_MS = 60 * 1000;
if (Platform.OS === 'android') {
  const timerFix = {};
  const runTask = (id, fn, ttl, args): void => {
    const waitingTime = ttl - Date.now();
    if (waitingTime <= 1) {
      InteractionManager.runAfterInteractions(() => {
        if (!timerFix[id]) {
          return;
        }
        delete timerFix[id];
        fn(...args);
      });
      return;
    }
    const afterTime = Math.min(waitingTime, MAX_TIMER_DURATION_MS);
    timerFix[id] = _setTimeout(() => runTask(id, fn, ttl, args), afterTime);
  };
  // @ts-ignore
  global.setTimeout = (fn, time, ...args): string | NodeJS.Timeout => {
    if (MAX_TIMER_DURATION_MS < time) {
      const ttl = Date.now() + time;
      const id = '_lt_' + Object.keys(timerFix).length;
      runTask(id, fn, ttl, args);
      return id;
    }
    return _setTimeout(fn, time, ...args);
  };
  global.clearTimeout = (id): void => {
    // @ts-ignore
    if (typeof id === 'string' && id.startsWith('_lt_')) {
      _clearTimeout(timerFix[id]);
      delete timerFix[id];
      return;
    }
    _clearTimeout(id);
  };
}
/// //////////////////////////////////////////////////////////////////////////

export default App;
