export const firebaseConfig = {
  apiKey: 'AIzaSyD81WoS81SRCJ96mQuYXb-OC07OT39aTdA',
  authDomain: 'covidrace-eb311.firebaseapp.com',
  databaseURL: 'https://covidrace-eb311.firebaseio.com',
  projectId: 'covidrace-eb311',
  storageBucket: 'covidrace-eb311.appspot.com',
  messagingSenderId: '403360842753',
  appId: '1:403360842753:web:ff5e086cfc92b9fc766c00',
  measurementId: 'G-WJ9DWVXRD3',
};

export const facebookAppId = '263549511464464';
export const facebookSecret = '1776ef037071aabb6250affdb002b2c3';

export const googleClientId = '403360842753-u6d55fs2dm6655u2pldshlfgiih15dot.apps.googleusercontent.com';
export const googleSecret = 'Er54u1UgZ2swCLNdCTp749LV';
